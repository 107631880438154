export default function Courses() {
  return (
    <>
      <div className="content container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">Courses</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
